import clsx from 'clsx';
import { HTMLProps, forwardRef } from 'react';
import InputMask from 'react-input-mask';

// import styles from '../form-elements.module.scss';
import styles from './Field.module.scss';

interface FieldProps extends HTMLProps<HTMLInputElement> {
  labelText: string;
  variant?: 'default' | 'white';
  error?: string;
  type?: 'phone' | 'text' | 'birthday';
  mask?: string;
  isValidIconBooleanQuery?: boolean;
  isLoading?: boolean;
}

export const Field = forwardRef<HTMLInputElement, FieldProps>(
  (
    {
      name,
      labelText,
      value,
      onChange,
      type = 'text',
      error,
      disabled = false,
      variant = 'default',
      mask,
      className,
      isValidIconBooleanQuery = false,
      isLoading,
      ...rest
    },
    ref
  ) => {
    const returnCurrentInput = () => {
      const options = {
        ref: ref,
        value: value,
        onChange: onChange,
        name: name,
        id: name,
        disabled: disabled || isLoading,
      };
      switch (type) {
        case 'text':
          return (
            // @ts-ignore
            <InputMask mask={mask ? mask : ''} {...options} {...rest} />
          );
        case 'phone':
          return (
            // @ts-ignore
            <InputMask
              mask={mask ? mask : '+7 (999) 999 99 99'}
              inputMode="numeric"
              {...options}
              {...rest}
            />
          );
        case 'birthday':
          return (
            // @ts-ignore
            <InputMask
              mask={mask ? mask : '99.99.9999'}
              inputMode="numeric"
              {...options}
              {...rest}
            />
          );
      }
    };

    return (
      <div
        className={clsx(
          className,
          styles.inputContainer,
          value && styles.filled,
          error && styles.error,
          variant === 'white' && styles.white
        )}
      >
        <label htmlFor={name}>{labelText}</label>
        {returnCurrentInput()}
        {isLoading && <span className={styles.loader} />}
        {isValidIconBooleanQuery && (
          <div className={styles.icon}>
            <svg
              width={24}
              height={24}
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.8">
                <path
                  d="M15 4.5L6.75 12.75L3 9"
                  stroke="#1DB175"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
          </div>
        )}
      </div>
    );
  }
);

Field.displayName = 'Field';

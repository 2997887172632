import Link from 'next/link';

import { Image } from '@/components/image';

import { SOCIAL_LINKS } from '@/constants/social.constant';

import styles from './ChatButton.module.scss';

type ChatButtonType = {
  type: keyof typeof chatData;
};

const chatData = {
  telegram: {
    title: 'Телеграм',
    color: '#32A9E6',
    src: '/static/icons/header/telegram.svg',
    link: SOCIAL_LINKS.telegram_support,
  },
  whatsapp: {
    title: 'WhatsApp',
    color: '#01D363',
    src: '/static/icons/header/whatsapp.svg',
    link: SOCIAL_LINKS.whatsapp_support_additional,
  },
};

export const ChatButton = ({ type }: ChatButtonType) => {
  const { title, color, src, link } = chatData[type];

  return (
    <Link
      href={link}
      passHref
      className={styles.chat_button}
      style={{ backgroundColor: color }}
      target="_blank"
    >
      <div className={styles.icon}>
        <Image src={src} width={24} height={24} alt="chat" />
      </div>
      <span className={styles.chat_button__text}>Написать в {title}</span>
    </Link>
  );
};

import clsx from 'clsx';

import { Image } from '@/components/image';

import { analyticMetric } from '@/helpers/analytics-metric';
import { jivoActions } from '@/helpers/jivoActions';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useTypedSelector } from '@/hooks/useTypedSelector';

import {
  setSelectedProduct,
  toggleModal,
} from '@/store/slices/one-click-order/one-click-order.slice';

import { SOCIAL_LINKS } from '@/constants/social.constant';

import styles from './OneClickModal.module.scss';

const ChatIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="currentColor"
    className="bi bi-chat"
    viewBox="0 0 16 16"
  >
    <path d="M2.678 11.894a1 1 0 0 1 .287.801 10.97 10.97 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8.06 8.06 0 0 0 8 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894zm-.493 3.905a21.682 21.682 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105z" />
  </svg>
);

const OneClickModal = () => {
  const { modalIsOpen, selectedProduct } = useTypedSelector((state) => state.oneClickOrder);
  const dispatch = useAppDispatch();

  const closeHandler = () => {
    dispatch(toggleModal());
    dispatch(setSelectedProduct(null));
  };

  return (
    <div className={clsx(styles.wrapper, !modalIsOpen && styles.hidden)}>
      <div className={styles.overlay} onClick={closeHandler} />
      <div className={styles.modalOuterWrapper}>
        <div className={styles.modalInnerWrapper}>
          <div className={styles.modal}>
            <button className={styles.close} onClick={closeHandler}>
              <Image src={'/static/icons/close_icon.svg'} width={24} height={24} alt="Закрыть" />
            </button>
            <div className={styles.title}>Купить с менеджером</div>
            <button
              className={clsx(styles.button, styles.chat)}
              onClick={() => {
                analyticMetric.hitGoal('knopka-chat-s-podderzhkoj');
                closeHandler();
                jivoActions.open();
              }}
            >
              {ChatIcon}
              <span>Чат с поддержкой</span>
            </button>
            <div className={styles.or}>Или</div>
            <a
              href={`${SOCIAL_LINKS.whatsapp_support}?text=Здравствуйте%20🌺%0AХочу%20заказать%20букет%20"${selectedProduct?.name}"`}
              className={clsx(styles.button, styles.whatsapp)}
              target="_blank"
              rel="noreferrer"
              onClick={() => analyticMetric.hitGoal('knopka-svyazatsya-v-whatsapp')}
            >
              <Image src="/static/icons/header/whatsapp.svg" width={24} height={24} alt="chat" />
              <span>Связаться в WhatsApp</span>
            </a>
            <div className={styles.or}>Или</div>
            <a
              href={SOCIAL_LINKS.telegram_support}
              className={clsx(styles.button, styles.telegram)}
              target="_blank"
              rel="noreferrer"
              onClick={() => analyticMetric.hitGoal('knopka-svyazatsya-v-telegram')}
            >
              <Image src="/static/icons/header/telegram.svg" width={24} height={24} alt="chat" />
              <span>Связаться в Telegram</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OneClickModal;

import { AxiosResponse } from 'axios';

import { uploadHttp } from '@/api/instances/uploadHttp';

import { IBaseMedia } from '@/types/new/media';

type MediaUploadResponse = {
  media: {
    data: Array<IBaseMedia>;
  };
  id: string;
};

export class UploadService {
  private static validateHasRef(formData: FormData) {
    return formData.has('ref') || formData.has('refId') || formData.has('field');
  }

  static async uploadFileWithoutRef(
    formData: FormData
  ): Promise<AxiosResponse<MediaUploadResponse>> {
    if (this.validateHasRef(formData)) {
      throw new Error('ref, refId, field are not allowed');
    }

    const response = await uploadHttp.post<MediaUploadResponse>('file/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response;
  }

  static async uploadFileWithRef(formData: FormData): Promise<AxiosResponse<MediaUploadResponse>> {
    if (!this.validateHasRef(formData)) {
      throw new Error('ref, refId, field are not allowed');
    }

    const response = await uploadHttp.post<MediaUploadResponse>('file/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response;
  }
}

import { Image } from '@/components/image';
import { ModalCloseButton } from '@/components/modal-close-button';
import ModalWrapper from '@/components/ui/modals/modal-wrapper/ModalWrapper';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useTypedSelector } from '@/hooks/useTypedSelector';

import { closeModal } from '@/store/slices/delivery-limits-modal/delivery-limits-modal.slice';

import stl from './DeliveryLimitsModal.module.scss';
import * as Assets from './assets';

const DeliveryLimitsModal = () => {
  const modal = useTypedSelector((state) => state.deliveryLimitsModal.modal);
  const dispatch = useAppDispatch();
  const closeHandler = () => dispatch(closeModal());

  return (
    <ModalWrapper isOpen={!!modal} pt={{ modal: { className: stl.modal } }} onClose={closeHandler}>
      <div className={stl.container}>
        {modal?.type === 'checkout' && (
          <>
            <div className={stl.checkoutTitle}>
              <p className={stl.title}>Это очень далеко</p>
              <Assets.SadSmileIcon />
            </div>
            <p className={stl.description}>{modal.text}</p>
          </>
        )}
        {modal?.type === 'common' && (
          <>
            <Image src={Assets.RestrictionIcon} width={86} height={86} alt="" />
            <p className={stl.title}>{modal.text}</p>
            <p className={stl.description}>
              Живые цветы не перенесут долгой дороги, поэтому мы ограничили доставку
            </p>
          </>
        )}
      </div>
      <button type="button" className={stl.okButton} onClick={closeHandler}>
        Понятно
      </button>
      <ModalCloseButton className={stl.closeButton} onClick={closeHandler} />
    </ModalWrapper>
  );
};
export default DeliveryLimitsModal;

import clsx from 'clsx';
import { FC, HTMLAttributes, useState } from 'react';
import { UsePinInputProps, usePinInput } from 'react-pin-input-hook';

import styles from './PinLoginUser.module.scss';

const PinLoginUser: FC<
  Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> & {
    onComplete?: UsePinInputProps['onComplete'];
    onChange?: UsePinInputProps['onChange'];
  }
> = ({ className, onComplete, onChange }) => {
  const [values, setValues] = useState(Array(4).fill(''));

  const { fields } = usePinInput({
    values,
    type: 'numeric',
    placeholder: 'x',
    autoFocus: true,
    onChange: async (values) => {
      setValues(values);
      onChange?.(values);
    },
    onComplete,
  });

  return (
    <div className={clsx(className, styles.pininput)}>
      {fields.map((propsField, index) => (
        <input key={index} className={styles.pininput__field} {...propsField} />
      ))}
    </div>
  );
};
export default PinLoginUser;

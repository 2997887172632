import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { ProductService } from '@/services/product.service';

import { IProductNew } from '@/types/new/products';

export const productsCheckerApi = createApi({
  reducerPath: 'checkoutProductsApi',
  baseQuery: fetchBaseQuery(),
  endpoints: (build) => ({
    getProductsInfo: build.query<IProductNew[], number[]>({
      queryFn: async (arr) => {
        const { data } = await ProductService.checkAvailableProductsById(arr);
        return { data: data.products };
      },
    }),
  }),
});

export const { useGetProductsInfoQuery, useLazyGetProductsInfoQuery } = productsCheckerApi;

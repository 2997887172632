import axios from 'axios';
import { useCallback } from 'react';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useTypedSelector } from '@/hooks/useTypedSelector';

import { setFormData } from '@/store/slices/basket-form/basket-form.slice';
import { changeInviteeId, setUser } from '@/store/slices/login-user/login-user.slice';

import { ICustomerNew } from '@/types/new/customers';

export const useLogin = () => {
  const { userId, inviteeId } = useTypedSelector((state) => state.user);
  const dispatch = useAppDispatch();

  return useCallback(
    async (code: string) => {
      const body = {
        code: Number(code),
        customerId: Number(userId),
        ...(inviteeId ? { inviteeId } : {}),
      };

      const { data } = await axios.post<ICustomerNew>('/api/auth/login', body);

      if (data) {
        const user = {
          name: data.name,
          phone: data.phone,
          email: data.email,
          bonusCount: data.bonus_count,
        };
        dispatch(
          setUser({
            userName: data.name ?? '',
            cashback: data?.bonus_level?.cashback || 0,
            bonusCount: data.bonus_count,
          })
        );
        dispatch(
          setFormData({
            customerName: user.name ?? '',
            customerPhone: user.phone,
            bonusCount: user.bonusCount,
          })
        );

        return dispatch(changeInviteeId(null));
      }
    },
    [changeInviteeId, dispatch, inviteeId, setFormData, userId]
  );
};

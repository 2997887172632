import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';

import { Image } from '@/components/image';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useTypedSelector } from '@/hooks/useTypedSelector';

import { toggleModal } from '@/store/slices/mobile-applicationn-qr-modal/mobile-application-qr-modal';

import styles from './MobileApplicationQrModal.module.scss';
import QrImage from './assets/qr-image.webp';

const CloseIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M7.05029 7.05023L16.9498 16.9497"
      stroke="#202020"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.05029 16.9498L16.9498 7.05027"
      stroke="#202020"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const MobileApplicationQrModal = () => {
  const isOpen = useTypedSelector((state) => state.mobileApplicationQrModal.modalIsOpen);

  const dispatch = useAppDispatch();
  const closeHandler = () => {
    dispatch(toggleModal());
  };

  return (
    <AnimatePresence initial={false}>
      {isOpen && (
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          exit={{
            opacity: 0,
          }}
          transition={{
            duration: 0.2,
            ease: 'easeIn',
          }}
          className={clsx(styles.wrapper)}
        >
          <div className={styles.overlay} onClick={closeHandler} />
          <motion.div
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
            transition={{
              duration: 0.2,
              ease: 'easeIn',
            }}
            className={styles.modal}
          >
            <div className={styles.content}>
              <div className={styles.title}>Цветы под рукой.</div>
              <span className={styles.subtitle}>Качай приложение на телефон!</span>
              <div className={styles.qr}>
                <Image
                  priority
                  src={QrImage}
                  alt="QR изображение для скачиваниея мобильного приложения"
                />
              </div>
              <button type="button" className={styles.closeButton} onClick={closeHandler}>
                {CloseIcon}
              </button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
export default MobileApplicationQrModal;

import { InvisibleSmartCaptcha } from '@yandex/smart-captcha';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';

import PinLoginUser from '@/components/checkout-form/login-user/PinLoginUser/PinLoginUser';
import { Image } from '@/components/image';
import Button from '@/components/ui/buttons/buttons/Button';
import { Field } from '@/components/ui/form-elements/field/Field';
import { PromotionsBlock } from '@/components/ui/modals/login-modal/promotions-block/PromotionsBlock';
import { useLoginModal } from '@/components/ui/modals/login-modal/useLoginModal';
import ModalWrapper from '@/components/ui/modals/modal-wrapper/ModalWrapper';

import env from '@/helpers/server/env';

import { useTypedSelector } from '@/hooks/useTypedSelector';

import { selectLastActivePromotion } from '@/store/api/promotions.api';

import { IPromotion } from '@/types/new/promotions';

import styles from './LoginModal.module.scss';
import * as Assets from './assets';

function SecondFormStep({
  isPinValid,
  isLoading,
  loginError,
  phoneNumber,
  onChangePhoneHandler,
  onPinInputChangeHandler,
  onLoginButtonClickHandler,
  onResendClick,
}: {
  phoneNumber: string;
  loginError: string;
  isPinValid: boolean;
  isLoading: boolean;
  onResendClick: () => void;
  onChangePhoneHandler: () => void;
  onPinInputChangeHandler: (values: unknown[]) => void;
  onLoginButtonClickHandler: () => Promise<void>;
}) {
  const [timer, setTimer] = useState(60);
  const timeOutCallback = useCallback(() => setTimer((currTimer) => currTimer - 1), []);

  useEffect(() => {
    let timeoutId: any;
    if (timer > 0) {
      timeoutId = setTimeout(timeOutCallback, 1000);
    }
    return () => {
      clearTimeout(timeoutId); // Clear the timeout when the component unmounts or when the timer changes
    };
  }, [timer, timeOutCallback]);

  const resetTimer = () => {
    if (!timer) {
      setTimer(60);
    }
  };

  const onResendButtonClick = () => {
    onResendClick();
    resetTimer();
  };

  return (
    <div>
      <div className={styles.top}>
        <div className={styles.title}>Введите код из SMS</div>
        <div className={styles.subtitle}>Отправили на номер {phoneNumber}</div>
        <p className={styles.editPhone}>
          <span onClick={onChangePhoneHandler}>Изменить номер</span>
        </p>
      </div>
      <div className={styles.content}>
        <PinLoginUser className={styles.pin} onChange={onPinInputChangeHandler} />
        <div>
          {loginError ? (
            <div className={styles.error}>{loginError}</div>
          ) : (
            <button
              className={styles.resend}
              disabled={Boolean(timer)}
              onClick={onResendButtonClick}
            >
              Отправить код ещё раз {!!timer && `(00:${timer.toString().padStart(2, '0')})`}
            </button>
          )}
        </div>

        <Button
          fullWidth
          disabled={!isPinValid || isLoading}
          className={styles.button}
          onClick={onLoginButtonClickHandler}
        >
          Войти
        </Button>
      </div>
    </div>
  );
}

export default function LoginModal() {
  const { loginPage } = useTypedSelector((state) => state.user);
  const isOpen = Boolean(loginPage);
  const activePromotion = useTypedSelector(selectLastActivePromotion);
  const router = useRouter();
  const isDevMode = router.query.devMode === 'true';

  const {
    states: {
      loginError,
      resetCaptchaKey,
      captchaStatus,
      sendCodeError,
      isSendCodeLoading,
      isPinValid,
      phoneNumber,
      isLoginLoading,
    },
    actions: {
      onPhoneInputChangeHandler,
      onCaptchaInit,
      onCloseHandler,
      onChangePhoneHandler,
      onCaptchaChallengeVisibleHandler,
      onCaptchaChallengeHiddenHandler,
      onCaptchaTokenExpiredHandler,
      onCaptchaNetworkErrorHandler,
      onPinInputChangeHandler,
      onLoginButtonClickHandler,
      onResendHandler,
    },
  } = useLoginModal({
    promotionCustomLoginNotify: activePromotion ? activePromotion.description.notification : '',
    isDevMode,
  });

  const returnStep = () => {
    switch (loginPage) {
      case 1:
        return (
          <div>
            <div className={styles.top}>
              <div className={styles.title}>Введите телефон, чтобы войти</div>
              <div className={styles.subtitle}>
                Мы отправим на номер SMS-сообщение с кодом подтверждения
              </div>
            </div>
            <div className={styles.content}>
              <Field
                value={phoneNumber}
                labelText="Номер телефона"
                name="phone"
                type="phone"
                isLoading={isSendCodeLoading || (isSendCodeLoading && captchaStatus !== 'success')}
                onChange={(e: ChangeEvent<HTMLInputElement>) => onPhoneInputChangeHandler(e)}
              />
              {sendCodeError && <div className={styles.error}>{sendCodeError}</div>}
            </div>
          </div>
        );
      case 2:
        return (
          <SecondFormStep
            isPinValid={isPinValid}
            isLoading={isLoginLoading}
            loginError={loginError}
            phoneNumber={phoneNumber}
            onChangePhoneHandler={onChangePhoneHandler}
            onPinInputChangeHandler={onPinInputChangeHandler}
            onResendClick={onResendHandler}
            onLoginButtonClickHandler={onLoginButtonClickHandler}
          />
        );
    }
  };

  return (
    <ModalWrapper
      isOpen={isOpen}
      pt={{ modal: { className: styles.modal }, bottomSheet: { className: styles.modal } }}
      onClose={onCloseHandler}
    >
      <div className={styles.wrapper}>
        <button className={clsx(styles.closeButton)} onClick={onCloseHandler}>
          <Image src={'/static/icons/close_icon.svg'} width={24} height={24} alt="Закрыть" />
        </button>
        <div className={styles.contentContainer}>{returnStep()}</div>
        <div className={styles.image}>
          <Image priority src={Assets.HalilusyaImage} fill alt="Халилуся" />
        </div>
        {activePromotion && <PromotionsBlock activePromotion={activePromotion as IPromotion} />}
      </div>
      <div className={styles.captcha}>
        {!isDevMode && (
          <InvisibleSmartCaptcha
            language="ru"
            key={resetCaptchaKey}
            visible={isOpen}
            sitekey={env.NEXT_PUBLIC_YANDEX_CAPTCHA_CLIENT_KEY ?? ''}
            hideShield={true}
            onSuccess={onCaptchaInit}
            onChallengeVisible={onCaptchaChallengeVisibleHandler}
            onNetworkError={onCaptchaNetworkErrorHandler}
            onTokenExpired={onCaptchaTokenExpiredHandler}
            onChallengeHidden={onCaptchaChallengeHiddenHandler}
          />
        )}
      </div>
    </ModalWrapper>
  );
}

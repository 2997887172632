import { Image } from '@/components/image';
import { ModalCloseButton } from '@/components/modal-close-button';
import ModalWrapper from '@/components/ui/modals/modal-wrapper/ModalWrapper';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useTypedSelector } from '@/hooks/useTypedSelector';

import { toggleModal } from '@/store/slices/flower-information-modal/flower-instruction-modal.slice';

import styles from './FlowerInstructionModal.module.scss';
import * as Assets from './assets';

const data = [
  {
    title: 'Снимите упаковку',
    text: 'Удалите все ленту и бумагу, чтобы ухаживать за цветами было легче.',
  },
  {
    title: 'Подготовьте вазу и наберите воду',
    text: 'Тщательной ее промойте, чтобы минимизировать возможные бактерии и обеспечить долгую жизнь цветам',
  },
  {
    title: 'Удалите листья',
    text: 'Под водой листья будут гнить, поэтому важно сразу очистить стебель от лишних',
  },
  {
    title: 'Подрежьте стебли',
    text: 'Перед тем, как поставить цветы в вазу, подрежьте каждый острым ножом под углом не больше 45°. Чем длиннее срез, тем лучше цветок будет пить.',
  },
  {
    title: 'Не забывайте менять воду',
    text: 'Также, очень важно обновлять срез каждого цвета. Регулярность — основа долгой жизни цветов.',
  },
  {
    title: 'Соблюдайте условия хранения',
    text: 'Держите цветы подальше от сквозняков, солнечных лучей и резких перепадов температуры',
  },
];

const FlowerInstructionModal = () => {
  const isOpen = useTypedSelector((state) => state.flowerInstructionModal.isOpen);
  const dispatch = useAppDispatch();
  const closeHandler = () => dispatch(toggleModal());

  return (
    <ModalWrapper
      isOpen={isOpen}
      pt={{ modal: { className: styles.modal } }}
      onClose={closeHandler}
    >
      <div className={styles.modalHeader}>
        <div className={styles.icon}>
          <Image src={Assets.Icon} alt="" fill />
        </div>
        <div className={styles.title}>Инструкция по уходу за цветами</div>
      </div>
      <p className={styles.description}>
        Для того, чтобы цветы дольше оставались свежими и радовали глаз, берите на заметку наши
        рекомендации по уходу
      </p>

      <div className={styles.instructionList}>
        {data.map((el, index) => (
          <InstructionItem key={index} count={index + 1} {...el} />
        ))}
      </div>
      <ModalCloseButton className={styles.closeButton} onClick={closeHandler} />
    </ModalWrapper>
  );
};

const InstructionItem = ({ count, title, text }: (typeof data)[number] & { count: number }) => {
  return (
    <div className={styles.item}>
      <div className={styles.header}>
        <span className={styles.count}>{count}</span> <span className={styles.title}>{title}</span>
      </div>
      <p className={styles.text}>{text}</p>
    </div>
  );
};

export default FlowerInstructionModal;

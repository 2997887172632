import clsx from 'clsx';
import React from 'react';

import { ChatButton } from '@/components/ui/modals/support-modal/chat-button/ChatButton';

import { jivoActions } from '@/helpers/jivoActions';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useTypedSelector } from '@/hooks/useTypedSelector';

import { changeModalVisibility } from '@/store/slices/support-modal/support-modal.slice';

import styles from './SupportModal.module.scss';

const JivoIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="currentColor"
    className="bi bi-chat"
    viewBox="0 0 16 16"
  >
    <path d="M2.678 11.894a1 1 0 0 1 .287.801 10.97 10.97 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8.06 8.06 0 0 0 8 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894zm-.493 3.905a21.682 21.682 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105z" />
  </svg>
);

function SupportModal() {
  const isOpen = useTypedSelector((state) => state.supportModal.isOpen);
  const dispatch = useAppDispatch();
  const onContentClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  function onClose() {
    dispatch(changeModalVisibility(false));
  }

  return (
    <div className={clsx(styles.wrapper, isOpen && styles.opened)}>
      <div className={styles.overlay} onClick={onClose}>
        <div className={styles.modal} onClick={onContentClick}>
          <div className={styles.heading}>Получите поддержку</div>
          <button
            className={styles.jivoButton}
            onClick={() => {
              onClose();
              jivoActions.open();
            }}
          >
            <div className={styles.icon}>{JivoIcon}</div>
            <span className={styles.text}>Чат на сайте</span>
          </button>
          <ChatButton type={'telegram'} />
          <ChatButton type={'whatsapp'} />
          <div className={styles.exitButton} onClick={onClose}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 34 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.3558 15.4681L24.2501 8.57377L25.7822 10.1058L18.8879 17.0001L25.7822 23.8944L24.2501 25.4265L17.3558 18.5322L10.4615 25.4265L8.92945 23.8944L15.8237 17.0001L8.92945 10.1058L10.4615 8.57377L17.3558 15.4681Z"
                fill="#6B6B6B"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SupportModal;

import clsx from 'clsx';
import { forwardRef, HTMLProps } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import styles from './TextareaField.module.scss';

interface TextareaProps extends HTMLProps<HTMLTextAreaElement> {
  labelText: string;
  variant?: 'default' | 'white';
  onChange: any;
  error?: string;
  withAutoSize?: boolean;
}

const TextareaField = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      name,
      labelText,
      placeholder,
      value,
      onChange,
      type = 'text',
      error,
      disabled = false,
      variant = 'default',
      className,
      withAutoSize = true,
      ...rest
    },
    ref
  ) => {
    return (
      <div
        className={clsx(
          className,
          styles.textareaContainer,
          value && styles.filled,
          !labelText && styles.withoutLabel,
          variant === 'white' && styles.white
        )}
      >
        {labelText && <label htmlFor={name}>{labelText}</label>}
        {withAutoSize ? (
          <TextareaAutosize
            // @ts-ignore
            ref={ref}
            name={name}
            value={value}
            placeholder={placeholder}
            cacheMeasurements
            onChange={onChange}
            {...rest}
          />
        ) : (
          <textarea // @ts-ignore
            ref={ref}
            id={name}
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            {...rest}
          />
        )}
      </div>
    );
  }
);

TextareaField.displayName = 'TextareaField';

export default TextareaField;

export const ACCESS_TOKEN_COOKIE_NAME = 'accessToken';
export const REFRESH_TOKEN_COOKIE_NAME = 'refreshToken';

export const YANDEX_METRIKA_PRODUCT_CLICK_CONTEXT = 'ya-metrika-product-click-context';

export const MINIMAL_PAYMENT_PRICE = 2299;

export const YANDEX_PAY_MERCHANT_ID = '00a66e4d-21fa-478a-af63-56a0a1da32c9';

export const YANDEX_CLIENT_UTM_SETTINGS = {
  name: 'is_utm_yandex_client',
  maxAge: 48 * 60 * 60, //48h
};

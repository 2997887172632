import Script from 'next/script';
import { Fragment } from 'react';

import env from '@/helpers/server/env';

export const Scripts = () => {
  const isGoogleEnabled =
    !!env.NEXT_PUBLIC_GOOGLE_UNIVERSAL_KEY &&
    !!env.NEXT_PUBLIC_GOOGLE_GTAG_KEY &&
    process.env.NODE_ENV === 'production';

  const isYandexEnabled =
    !!env.NEXT_PUBLIC_YANDEX_METRIKA_SITE_KEY && process.env.NODE_ENV === 'production';

  const isJivoEnabled = process.env.NODE_ENV === 'production';

  return (
    <>
      {isYandexEnabled && (
        <Script
          id="yandex-metric"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
  m[i].l=1*new Date();
  for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
  k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
  (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

  ym(${env.NEXT_PUBLIC_YANDEX_METRIKA_SITE_KEY}, "init", {
  clickmap:true,
  trackLinks:true,
  accurateTrackBounce:true,
  webvisor:true,
  ecommerce:"yaDataLayer"  
});
window.yaDataLayer = window.yaDataLayer || [];`,
          }}
        />
      )}

      {isGoogleEnabled && (
        <Fragment>
          {/*#region Google*/}
          <Script
            id="google-universal"
            strategy="afterInteractive"
            src={`https://www.googletagmanager.com/gtag/js?id=${env.NEXT_PUBLIC_GOOGLE_UNIVERSAL_KEY}`}
          />
          <Script
            id="gtag"
            strategy="afterInteractive"
            src={`https://www.googletagmanager.com/gtag/js?id=${env.NEXT_PUBLIC_GOOGLE_GTAG_KEY}`}
          />
          <Script id="gtag-initializer" strategy="afterInteractive">
            {`
          window.dataLayer=window.dataLayer || [];
          function gtag(){window.dataLayer?.push(arguments);}
          window.gtag=gtag;
          gtag('js', new Date());
          gtag('config', '${env.NEXT_PUBLIC_GOOGLE_UNIVERSAL_KEY}');
          gtag('config','${env.NEXT_PUBLIC_GOOGLE_GTAG_KEY}');`}
          </Script>
          {/*#endregion Google*/}
        </Fragment>
      )}

      {isJivoEnabled && (
        <Fragment>
          <Script id="jivo" src="//code.jivo.ru/widget/faBonVs717" strategy="afterInteractive" />
          {/*
      https://www.jivo.ru/help/customization/sozdaem-svoi-iarlyk-onlain-konsultanta.html
      Если надо будет сделать свою кнопку на сайте - следовать инструкции
      На текущий момент кнопки полностью выключены, остался только виджет
      */}
          <Script id="jivo" src="/scripts/custom-jivo-widget.js" strategy="afterInteractive" />
        </Fragment>
      )}
    </>
  );
};

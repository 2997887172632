import clsx from 'clsx';
import Link from 'next/link';
import { HTMLAttributes } from 'react';

import { IPromotion } from '@/types/new/promotions';

import { ROUTES } from '@/constants/routes.constant';

import styles from './PromotionsBlock.module.scss';

type Props = HTMLAttributes<HTMLDivElement> & {
  activePromotion: IPromotion;
};

const ArrowIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
    <path
      d="M3.41663 8.47717H14.0833M14.0833 8.47717L10.0833 4.47717M14.0833 8.47717L10.0833 12.4772"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PromotionsBlock = ({ activePromotion, className, ...rest }: Props) => {
  return (
    <div className={clsx(styles.wrapper, className)} {...rest}>
      <div className={styles.title}>{activePromotion.name}</div>
      <p className={styles.description}>{activePromotion.description.full}</p>
      <Link
        href={ROUTES.PROMOTIONS.POLICY_INFO(String(activePromotion.id))}
        className={styles.link}
        target="_blank"
      >
        Ознакомиться с условиями {ArrowIcon}
      </Link>
    </div>
  );
};

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { ProductService } from 'src/services/product.service';

import { IProductCategory } from '@/types/new/products';

export const categoriesApi = createApi({
  reducerPath: 'categoriesApi',
  baseQuery: fetchBaseQuery(),
  endpoints: (build) => ({
    getCategories: build.query<IProductCategory[], void>({
      queryFn: async () => {
        const { data: categories } = await ProductService.getAllCategories();

        return { data: categories.items };
      },
    }),
  }),
});

export const { useGetCategoriesQuery } = categoriesApi;

export const useGetCategoriesState = categoriesApi.endpoints?.getCategories.useQueryState;

import Link from 'next/link';
import { ReactNode } from 'react';

import { ModalCloseButton } from '@/components/modal-close-button';
import ModalWrapper from '@/components/ui/modals/modal-wrapper/ModalWrapper';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useTypedSelector } from '@/hooks/useTypedSelector';

import { toggleModal } from '@/store/slices/payment-information-modal/payment-information-modal.slice';

import { ROUTES } from '@/constants/routes.constant';

import styles from './PaymentInformationModal.module.scss';
import * as Assets from './assets';

interface IPayment {
  title: string;
  items: {
    title: string;
    additionalText?: string;
    icon: ReactNode;
  }[];
}

const paymentData: Record<string, IPayment> = {
  defaultPayment: {
    title: 'Оплата',
    items: [
      {
        title: 'Картой',
        additionalText: 'Mir, Visa, Mastercard, UnionPay',
        icon: <Assets.CardIcon />,
      },
      {
        title: 'T-Pay',
        icon: <Assets.T_PayIcon />,
      },
      {
        title: 'Sber Pay',
        icon: <Assets.SberbankIcon />,
      },
      {
        title: 'Yandex Pay',
        icon: <Assets.YandexIcon />,
      },
      {
        title: 'СБП',
        icon: <Assets.SbpIcon />,
      },
      {
        title: 'Робокасса',
        icon: <Assets.RobokassaIcon />,
      },
      {
        title: 'ЮКасса',
        icon: <Assets.YoomoneyIcon />,
      },
    ],
  },
  partsPayment: {
    title: 'Частями',
    items: [
      {
        title: 'Долями',
        additionalText: 'х4 платежа',
        icon: <Assets.DolyameIcon />,
      },
      {
        title: 'Подели',
        additionalText: 'х4 платежа',
        icon: <Assets.PodelyIcon />,
      },
    ],
  },
  creditPayment: {
    title: 'Рассрочка',
    items: [
      {
        title: 'Т-Банк',
        icon: <Assets.T_PayIcon />,
      },
      {
        title: 'ОТП Банк',
        icon: <Assets.OtpIcon />,
      },
      {
        title: 'Хоум Банк',
        icon: <Assets.HoumIcon />,
      },
    ],
  },
};

const PaymentInformationModal = () => {
  const isOpen = useTypedSelector((state) => state.paymentInformationModal.isOpen);

  const dispatch = useAppDispatch();
  const closeHandler = () => dispatch(toggleModal());

  return (
    <ModalWrapper
      isOpen={isOpen}
      pt={{ modal: { className: styles.modal } }}
      onClose={closeHandler}
    >
      <div className={styles.mainTitle}>Информация об оплате</div>
      <div className={styles.paymentBlock}>
        <div className={styles.title}>{paymentData.defaultPayment.title}</div>
        <div className={styles.defaultPaymentGrid}>
          {paymentData.defaultPayment.items.map((el, index) => (
            <PaymentItem key={index} {...el} />
          ))}
        </div>
      </div>
      <div className={styles.paymentBlock}>
        <div className={styles.title}>Частями</div>
        <div className={styles.partPaymentGrid}>
          {paymentData.partsPayment.items.map((el, index) => (
            <PaymentItem key={index} {...el} />
          ))}
        </div>
      </div>
      <div className={styles.paymentBlock}>
        <div className={styles.title}>Рассрочка</div>
        <div className={styles.creditPaymentGrid}>
          {paymentData.creditPayment.items.map((el, index) => (
            <PaymentItem key={index} {...el} />
          ))}
        </div>
      </div>

      <Link href={ROUTES.PAYMENT} className={styles.link}>
        Подробнее об оплате
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
        >
          <path
            d="M6 13.1663L10 8.49967L6 3.83301"
            stroke="#5C80FF"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Link>
      <ModalCloseButton className={styles.closeButton} onClick={closeHandler} />
    </ModalWrapper>
  );
};
export default PaymentInformationModal;

const PaymentItem = ({ icon, title, additionalText }: IPayment['items'][number]) => {
  return (
    <div className={styles.item}>
      {icon}
      <div>
        {title} {additionalText && <span>{additionalText}</span>}
      </div>
    </div>
  );
};

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { ProductService } from '@/services/product.service';

import { IProductRubric } from '@/types/new/products';

export const rubricsApi = createApi({
  reducerPath: 'rubricsApi',
  baseQuery: fetchBaseQuery(),
  endpoints: (build) => {
    return {
      getRubrics: build.query<IProductRubric[], void>({
        queryFn: async () => {
          const { data: rubrics } = await ProductService.getAllRubrics();
          return { data: rubrics.items };
        },
      }),
    };
  },
});

export const { useGetRubricsQuery } = rubricsApi;

export const useGetRubricsState = rubricsApi.endpoints.getRubrics.useQueryState;

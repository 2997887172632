import axios from 'axios';
import { getCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { useLogin } from '@/components/ui/modals/login-modal/useLogin';

import { analyticMetric } from '@/helpers/analytics-metric';

import { useTypedSelector } from '@/hooks/useTypedSelector';
import { utmCookiesNames } from '@/hooks/useUtmSave';

import { useBasketNavigation } from '@/pages/Checkout/basket-form/useBasketNavigation';

import { changeFormStep } from '@/store/slices/basket-form/basket-form.slice';
import { changeLoginPage, loginUser, setUser } from '@/store/slices/login-user/login-user.slice';

import { IOrderUtm } from '@/types/new/orders';

const normalizePhone = (phone: string) => phone.replace(/[^\d]/g, '');

type ISendCodeBody = {
  phone: string;
  captcha?: string;
  utm?: IOrderUtm;
};

export const useLoginModal = ({
  promotionCustomLoginNotify,
  isDevMode,
}: {
  promotionCustomLoginNotify?: string;
  isDevMode: boolean;
}) => {
  const [captchaKey, setCaptchaKey] = useState<string>('');
  const [resetCaptchaKey, setResetCaptchaKey] = useState<number>(0);
  const [sendCodeError, setSendCodeError] = useState<string>('');
  const [loginError, setLoginError] = useState<string>('');
  const [isSendCodeLoading, setIsSendCodeLoading] = useState<boolean>(false);
  const [isLoginLoading, setIsLoginLoading] = useState<boolean>(false);
  const [isResendTriggered, setIsResendTriggered] = useState<boolean>(false);
  const [pinInputValue, setPinInputValue] = useState<string>('');
  const [isNewCustomer, setIsNewCustomer] = useState<boolean>(false);

  const [captchaStatus, setCaptchaStatus] = useState<
    'visible' | 'hidden' | 'network-error' | 'success' | 'token-expired'
  >('hidden');

  const [phoneNumber, setPhoneNumber] = useState<string>('');

  const dispatch = useDispatch();
  const { loginPage } = useTypedSelector((state) => state.user);
  const { isTotalSumLowerWhenMinimal } = useBasketNavigation();

  const router = useRouter();
  const successNotify = useCallback((title: string) => toast.success(title), []);

  /** Обработчик отправки кода подтверждения **/
  const sendCode = async () => {
    const body: ISendCodeBody = {
      phone: normalizePhone(phoneNumber),
      captcha: captchaKey,
    };

    utmCookiesNames.forEach((name) => {
      const value = getCookie(name);
      if (value) {
        body.utm = {
          ...body.utm,
          [name]: value,
        };
      }
    });

    try {
      const { data } = await axios.post<{
        customerId: number;
        cashback: number;
        is_new_customer: boolean;
      }>('/api/auth/send-code', body);

      data.is_new_customer && analyticMetric.hitGoal('new-customer');
      analyticMetric.hitGoal('registraciya:-nomer-vveden-uspeshno');
      dispatch(setUser({ userId: data.customerId, cashback: data.cashback }));
      dispatch(changeLoginPage(2));
      setIsNewCustomer(data.is_new_customer);
      setCaptchaStatus('token-expired');
    } catch (e: any) {
      console.error(e);
      setSendCodeError(
        (e.response?.data?.error?.code === 'auth_005'
          ? 'Что-то пошло не так, попробуйте еще раз'
          : e.response?.data?.error?.message) || 'Что-то пошло не так, попробуйте еще раз'
      );
      resetCaptchaKeyHandler();
    }
  };

  /** Обработчик инпута с номером **/
  const onPhoneInputChangeHandler = async (e: ChangeEvent<HTMLInputElement>) => {
    if (sendCodeError) setSendCodeError('');

    const value = e.target.value;
    setPhoneNumber(value);
  };

  /** Обработчик закрытия модального окна **/
  const onCloseHandler = useCallback(() => {
    dispatch(changeLoginPage(null));
    setSendCodeError('');
    setPhoneNumber('');
    setCaptchaKey('');
    setResetCaptchaKey(1);
    setIsSendCodeLoading(false);
    setCaptchaStatus('hidden');
  }, []);

  /** Обработчик нажатия на "Изменить номер" **/
  const onChangePhoneHandler = () => {
    dispatch(changeLoginPage(1));
    setSendCodeError('');
    setPhoneNumber('');
    setCaptchaKey('');
    resetCaptchaKeyHandler();
  };

  /** Обработчик ввода кода подтверждения **/
  const onPinInputChangeHandler = (values: unknown[]) => {
    setLoginError('');
    const filteredValues = values.filter(Boolean);

    setPinInputValue(filteredValues.join(''));
  };

  /** Обработчик входа после успешного ввода кода подтверждения **/
  const onLoginHandler = useLogin();

  /** Обработчик нажатия на "Войти" **/
  const onLoginButtonClickHandler = async () => {
    try {
      setIsLoginLoading(true);
      await onLoginHandler(pinInputValue);
      dispatch(loginUser());

      //Если пользователь авторизовался в корзине, перекинуть его на 2 шаг
      if (router.pathname.includes('checkout') && !isTotalSumLowerWhenMinimal) {
        dispatch(changeFormStep(2));
      }

      analyticMetric.hitGoal('knopka-vojti-registraciya');
      successNotify(
        isNewCustomer && promotionCustomLoginNotify
          ? promotionCustomLoginNotify
          : 'Спасибо за авторизацию!'
      );
      onCloseHandler();
    } catch (e: any) {
      console.error(e);
      const errorMessage =
        e.code === 'ERR_NETWORK'
          ? 'Отсутствует подключение к интернету'
          : e.response?.data?.error?.message || 'Ошибка';

      setLoginError(errorMessage);
    } finally {
      setIsLoginLoading(false);
    }
  };

  /** Обработчик нажатия на "Отправить код еще раз" **/
  const onResendHandler = async () => {
    resetCaptchaKeyHandler();
    setIsResendTriggered(true);
  };

  /** Триггер на ресенд. Не смог придумать чего то более элегантного. Основная проблема - токен не обновляется сразу и приходится ждать его **/
  useEffect(() => {
    if (captchaStatus === 'success' && isResendTriggered) {
      sendCode();
      setIsResendTriggered(false);
    }
  }, [captchaStatus, isResendTriggered]);

  /** Обработчик инициализации капчи **/
  const onCaptchaInit = (captcha: string) => {
    setCaptchaKey(captcha);
    setCaptchaStatus('success');
  };

  /** Обработчик сброса состояния капчи **/
  const resetCaptchaKeyHandler = () => setResetCaptchaKey((prev) => prev + 1);

  /** Метод вызывается, когда появляется всплывающее окно с заданием **/
  const onCaptchaChallengeVisibleHandler = useCallback(() => setCaptchaStatus('visible'), []);

  /** Метод вызывается, когда закрывается всплывающее окно с заданием **/
  const onCaptchaChallengeHiddenHandler = useCallback(() => {
    onCloseHandler();
    setCaptchaStatus('hidden');
  }, [onCloseHandler]);

  /** Метод вызывается, когда происходит ошибка сети **/
  const onCaptchaNetworkErrorHandler = useCallback(() => {
    setCaptchaStatus('network-error');
    onCloseHandler();
  }, []);

  /** Метод вызывается, когда токен, полученный пользователем после прохождения проверки, становится невалидным **/
  const onCaptchaTokenExpiredHandler = useCallback(() => {
    onCloseHandler();
  }, []);

  /** Используется для отправки кода, когда введен валидный номер телефона (11 цифр) **/
  useEffect(() => {
    if (!(normalizePhone(phoneNumber).length === 11 && !sendCodeError && loginPage === 1)) {
      return;
    }

    setIsSendCodeLoading(true);
    if (captchaKey || isDevMode) {
      sendCode().finally(() => setIsSendCodeLoading(false));
    }
  }, [phoneNumber, captchaKey, sendCodeError]);

  return {
    states: {
      phoneNumber,
      captchaStatus,
      resetCaptchaKey,
      sendCodeError,
      loginError,
      isSendCodeLoading,
      isLoginLoading,
      isPinValid: pinInputValue.length === 4,
    },
    actions: {
      onPhoneInputChangeHandler,
      onCaptchaInit,
      onCloseHandler,
      onChangePhoneHandler,
      onCaptchaChallengeVisibleHandler,
      onCaptchaChallengeHiddenHandler,
      onCaptchaNetworkErrorHandler,
      onCaptchaTokenExpiredHandler,
      onPinInputChangeHandler,
      onLoginButtonClickHandler,
      onResendHandler,
    },
  };
};

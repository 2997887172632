import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

export const catalogueApi = createApi({
  reducerPath: 'catalogueApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api',
  }),
  tagTypes: ['CatalogueTag'],
  endpoints: (build) => ({
    getCatalogueMenu: build.query<
      {
        menu: Array<{
          title: string;
          icon: string;
          href?: string;
          submenu: Array<{
            name: string;
            href: string;
            slug?: string;
          }>;
        }>;
      },
      void
    >({
      providesTags: ['CatalogueTag'],
      query: () => ({
        url: 'catalogue/menu',
      }),
    }),
  }),
});

export const { useGetCatalogueMenuQuery } = catalogueApi;

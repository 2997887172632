import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { HeaderMenuService } from '@/services/header-menu.service';

import { THeaderMenuItem } from '@/types/new/header-menu';

export const headerMenuApi = createApi({
  reducerPath: 'headerMenuApi',
  baseQuery: fetchBaseQuery(),
  tagTypes: ['HeaderMenuTag'],
  endpoints: (build) => ({
    getHeaderMenu: build.query<THeaderMenuItem[], void>({
      providesTags: ['HeaderMenuTag'],
      queryFn: async () => {
        const { data: header } = await HeaderMenuService.getMenu();
        return { data: header?.header_menu?.header_menu?.items };
      },
    }),
  }),
});

export const { useGetHeaderMenuQuery } = headerMenuApi;

import dayjs from 'dayjs';
import ru from 'dayjs/locale/ru';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { ReactNode, useEffect } from 'react';

import { ModalCloseButton } from '@/components/modal-close-button';
import ModalWrapper from '@/components/ui/modals/modal-wrapper/ModalWrapper';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useTypedSelector } from '@/hooks/useTypedSelector';

import { closeModal } from '@/store/slices/parts-payment-modal/parts-payment-modal.slice';

import { ROUTES } from '@/constants/routes.constant';

import styles from './PartsPaymentModal.module.scss';
import * as Assets from './assets';

type TPayment = {
  advantages?: { icon: ReactNode; text: string }[];
  accentColor: string;
  instruction: string[];
  icon: ReactNode;
  aboutPage: { link: string; text: string; target: string };
  header: { subtitle: string; title: string };
  title: string;
};

const PAYMENT_VARIANTS_MAP: Record<string, TPayment> = {
  dolyame: {
    icon: <Assets.DolyameLogo />,
    title: 'Долями',
    header: {
      title: 'Оплатите 25% от стоимости покупки',
      subtitle: 'Оставшиеся 3 части спишутся автоматически с шагом в две недели',
    },
    accentColor: '#000',
    advantages: [
      { icon: <Assets.DolyameAdvIcon1 />, text: 'Без процентов и комиссий' },
      { icon: <Assets.DolyameAdvIcon2 />, text: 'Как обычная оплата картой' },
    ],
    instruction: [
      'При оформлении заказа выберете способ оплаты «Оплата Долями»',
      'Укажите номер телефона и привяжите карту, которой будете оплачивать покупки',
      'Списание 25% стоимости покупки с вашей карты',
      'Оставшиеся 3 платежа будут списываться каждые 2 недели',
    ],
    aboutPage: {
      text: 'Подробнее о Долями',
      link: ROUTES.DOLYAME,
      target: '_self',
    },
  },
  podeli: {
    icon: <Assets.PodeliLogo />,
    title: 'Подели',
    header: {
      title: 'Оплатите 25% от стоимости покупки',
      subtitle: 'Оставшиеся 3 части спишутся автоматически с шагом в две недели',
    },
    accentColor: '#EF3124',
    advantages: [
      { icon: <Assets.PodeliAdvIcon1 />, text: 'Без процентов и комиссий' },
      { icon: <Assets.PodeliAdvIcon2 />, text: 'Как обычная оплата картой' },
    ],
    instruction: [
      'При оформлении заказа выберете способ оплаты «Оплата Подели»',
      'Укажите номер телефона и привяжите карту, которой будете оплачивать покупки',
      'Списание 25% стоимости покупки с вашей карты',
      'Оставшиеся 3 платежа будут списываться каждые 2 недели',
    ],
    aboutPage: {
      text: 'Подробнее о Подели',
      link: ROUTES.PODELI,
      target: '_self',
    },
  },
  split: {
    icon: <Assets.SplitLogo />,
    title: 'Сплит',
    header: {
      title: 'Делите стоимость любой покупки на несколько платежей',
      subtitle: 'Базовый Сплит на 2 месяца — без переплат',
    },
    accentColor: '#7ED397',
    instruction: [
      'При оформлении заказа выберете способ оплаты «Оплата Сплит»',
      'Укажите номер телефона и привяжите карту, которой будете оплачивать покупки',
      'Списание 25% стоимости покупки с вашей карты',
      'Оставшиеся 3 платежа будут списываться каждые 2 недели',
    ],
    aboutPage: {
      text: 'Подробнее о Сплит',
      link: ROUTES.YANDEX_SPLIT,
      target: '_self',
    },
  },
};

const PartsPaymentModal = () => {
  const router = useRouter();
  const { isOpen, price, variant } = useTypedSelector((state) => state.partsPaymentModal);
  const dispatch = useAppDispatch();

  const getPartsInformation = () => {
    let info: { price: number; date: number }[] = [];

    for (let i = 0; i < 4; i++) {
      info.push({
        price: Math.floor(price / 4),
        date: dayjs(new Date())
          .add(2 * i, 'week')
          .valueOf(),
      });
    }

    return info;
  };

  const closeHandler = () => dispatch(closeModal());

  useEffect(() => {
    if (isOpen) {
      closeHandler();
    }
  }, [router.asPath]);

  const onLinkClick = () => {
    dispatch(closeModal());
  };

  return (
    <ModalWrapper
      isOpen={isOpen}
      pt={{
        modal: {
          className: styles.modal,
        },
        bottomSheet: {
          className: styles.bottomSheet,
        },
      }}
      onClose={closeHandler}
    >
      <div className={styles.header}>
        {PAYMENT_VARIANTS_MAP[variant]?.icon}
        <span className={styles.text}>{PAYMENT_VARIANTS_MAP[variant]?.title}</span>
      </div>
      <div className={styles.body}>
        <div className={styles.title}>{PAYMENT_VARIANTS_MAP[variant]?.header?.title}</div>
        <p className={styles.subtitle}>{PAYMENT_VARIANTS_MAP[variant]?.header?.subtitle}</p>
      </div>
      {!!PAYMENT_VARIANTS_MAP[variant]?.advantages && (
        <div className={styles.advantages}>
          {PAYMENT_VARIANTS_MAP[variant].advantages!.map((el) => (
            <div key={el.text} className={styles.item}>
              {el.icon}
              <span className={styles.text}>{el.text}</span>
            </div>
          ))}
        </div>
      )}

      <div className={styles.parts}>
        {getPartsInformation().map((el, index) => {
          const day = dayjs(el.date).locale(ru).format('DD');
          const month = dayjs(el.date).locale(ru).format('MMMM').slice(0, 3);

          const date = index === 0 ? 'Сегодня' : `${day} ${month}`;

          return (
            <div key={index} className={styles.item}>
              <span className={styles.date}>{date}</span>
              <span className={styles.price}>{el.price} ₽</span>
              <span
                className={styles.indicator}
                style={{ backgroundColor: PAYMENT_VARIANTS_MAP[variant]?.accentColor }}
              />
            </div>
          );
        })}
      </div>
      <div className={styles.instruction}>
        <div className={styles.title}>Как оформить ?</div>
        <ul className={styles.list}>
          {PAYMENT_VARIANTS_MAP[variant]?.instruction.map((el) => (
            <li key={el}>{el}</li>
          ))}
        </ul>
      </div>
      <Link
        className={styles.link}
        target={PAYMENT_VARIANTS_MAP[variant]?.aboutPage.target}
        href={PAYMENT_VARIANTS_MAP[variant]?.aboutPage.link}
        onClick={onLinkClick}
      >
        {PAYMENT_VARIANTS_MAP[variant]?.aboutPage.text}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M6 12.6663L10 7.99967L6 3.33301"
            stroke="#5C80FF"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Link>
      <ModalCloseButton className={styles.closeButton} onClick={closeHandler} />
    </ModalWrapper>
  );
};

export default PartsPaymentModal;

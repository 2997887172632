import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { AdditionalProductCard } from '@/components/additional-product-card';
import { ModalCloseButton } from '@/components/modal-close-button';
import ModalWrapper from '@/components/ui/modals/modal-wrapper/ModalWrapper';

import { formatCurrency } from '@/helpers/number/format-currency';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useTypedSelector } from '@/hooks/useTypedSelector';

import { closeModalWithAdditionalProducts } from '@/store/slices/checkout-product/checkout-product.slice';

import { MINIMAL_PAYMENT_PRICE } from '@/constants/common.constants';

import styles from './AdditionalCheckoutProductsModal.module.scss';

const CLoseIcon = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.05078 7.05029L16.9503 16.9498"
      stroke="#1B1B1B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.05078 16.9497L16.9503 7.05021"
      stroke="#1B1B1B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const AdditionalCheckoutProductsModal = () => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const {
    additionalProducts: { products, modalIsOpen },
    sum,
  } = useTypedSelector((state) => state.checkoutProducts);

  const onClose = () => {
    dispatch(closeModalWithAdditionalProducts());
  };

  useEffect(() => {
    router.events.on('routeChangeStart', onClose);
    return () => {
      router.events.off('routeChangeStart', onClose);
    };
  }, []);

  return (
    <ModalWrapper
      isOpen={modalIsOpen && !!products.length}
      pt={{ modal: { className: styles.modal }, bottomSheet: { className: styles.bottomSheet } }}
      onClose={onClose}
    >
      <div className={styles.mobileHeader}>
        <button onClick={onClose}>{CLoseIcon}</button>
      </div>
      <div className={styles.title}>
        {sum < MINIMAL_PAYMENT_PRICE ? (
          <>
            Добавьте еще товаров на сумму:{' '}
            <span>{formatCurrency(MINIMAL_PAYMENT_PRICE - sum)}</span>
          </>
        ) : (
          'Добавить к заказу'
        )}
      </div>
      {sum < MINIMAL_PAYMENT_PRICE && (
        <p className={styles.description}>
          Минимальная сумма оплаты {formatCurrency(MINIMAL_PAYMENT_PRICE)}
        </p>
      )}
      <div className={styles.grid}>
        {products.map((product) => (
          <AdditionalProductCard key={product.id} product={product} />
        ))}
      </div>
      <ModalCloseButton className={styles.closeButton} onClick={onClose} />
    </ModalWrapper>
  );
};

import DolyameAdvIcon1 from './d-adv-1.svg';
import DolyameAdvIcon2 from './d-adv-2.svg';
import DolyameLogo from './dolyame.svg';
import PodeliAdvIcon1 from './p-adv-1.svg';
import PodeliAdvIcon2 from './p-adv-2.svg';
import PodeliLogo from './podeli.svg';
import SplitLogo from './split.svg';

export {
  DolyameLogo,
  DolyameAdvIcon1,
  DolyameAdvIcon2,
  PodeliAdvIcon1,
  PodeliAdvIcon2,
  PodeliLogo,
  SplitLogo,
};

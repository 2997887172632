import _set from 'lodash/set';
import qs from 'qs';

import { newBackendHttp, newBackendHttpV2 } from '@/api/instances/rest';

import { IPaginated, IUniversalResponse } from '@/types/new/backend-responses';
import type {
  IGetProductsByFilters,
  IGetProductsByRubric,
  IGetProductsByRubricId,
  IGetProductsBySearchQuery,
  IPostcard,
  IProductAttribute,
  IProductCategory,
  IProductNew,
  IProductRubric,
  IRubricBundle,
} from '@/types/new/products';

export interface IFiltersProps {
  categories?: string;
  category_slugs?: string;
  rubrics?: string;
  rubric_slugs?: string;
  flowers?: string;
  attr_values?: string;
  sort?: string;
  price_from?: number;
  price_to?: number;
  limit?: number;
  start?: number;
}

export class ProductService {
  static async getBySearchQuery(
    query: string,
    sort?: string,
    limit = 20,
    offset = 0
  ): Promise<IGetProductsBySearchQuery> {
    const searchParams = qs.stringify(
      {
        q: query,
        sort: [sort],
        limit,
        offset,
      },
      { encodeValuesOnly: true }
    );

    const { data } = await newBackendHttpV2.get<IGetProductsBySearchQuery>(
      `meilisearch/search-products?${searchParams}`
    );

    return data;
  }

  static async getByFilters({
    sort = 'rank:asc',
    price_from = 0,
    price_to = 500000,
    limit = 50,
    start = 0,
    ...rest
  }: IFiltersProps): Promise<IGetProductsByFilters> {
    const searchParams = qs.stringify({
      sort,
      price_from,
      price_to,
      limit,
      start,
      ...rest,
    });

    const { data } = await newBackendHttpV2.get<IGetProductsByFilters>(
      `/products/filters?${searchParams}`
    );

    return data;
  }

  static async getAdditionalProducts(): Promise<IUniversalResponse<IPaginated<IProductNew[]>>> {
    const IDS = [6052, 6218, 6500, 6403, 11603, 11549, 6058, 12728];
    const query = qs.stringify({
      include: {
        components: true,
      },
      where: {
        id: {
          in: IDS,
        },
      },
    });

    const { data } = await newBackendHttpV2.get<IUniversalResponse<IPaginated<IProductNew[]>>>(
      `/products?${query}`
    );

    const sortedProducts = data.data.items.sort((a, b) => IDS.indexOf(a.id) - IDS.indexOf(b.id));

    return _set(data, 'data.items', sortedProducts);
  }

  static async getByRubricSlug(
    slug: string,
    skip = 0,
    take = 10,
    sort?: string | null
  ): Promise<IGetProductsByRubric> {
    const sortValues = sort?.split(':');
    const searchParams = qs.stringify(
      {
        skip,
        take,
        ...(sortValues ? { orderBy: { [sortValues[0]]: sortValues?.[1] || 'asc' } } : {}),
      },
      { encodeValuesOnly: true }
    );

    const { data } = await newBackendHttpV2.get<IGetProductsByRubric>(
      `/rubrics/from-slug/${slug}?${searchParams}`
    );

    return data;
  }

  static async getByRubricId(id: number, skip = 0, take = 4): Promise<IGetProductsByRubricId> {
    const searchParams = qs.stringify({
      include: {
        products: { skip, take, orderBy: [{ product_order: 'asc' }] },
      },
    });

    const { data } = await newBackendHttpV2.get<IGetProductsByRubricId>(
      `/rubrics/${id}?${searchParams}`
    );

    return data;
  }

  static async getOneBySlug(
    slug: string,
    options?: { include?: Record<'rubrics' | 'category', boolean> }
  ): Promise<IProductNew> {
    const query = qs.stringify({
      include: {
        components: true,
        ...options?.include,
      },
    });

    const { data } = await newBackendHttpV2.get<IUniversalResponse<IProductNew>>(
      `/products/slug/${slug}?${query}`
    );

    return data.data;
  }

  static async getAvalableAttributes(
    filters?: IFiltersProps
  ): Promise<IUniversalResponse<IProductAttribute[]>> {
    const searchParams = qs.stringify(filters);

    const { data } = await newBackendHttp.get<IUniversalResponse<IProductAttribute[]>>(
      `/products/filters/available-attributes?${searchParams}`
    );

    return data;
  }

  static async getAllCategories(): Promise<IUniversalResponse<IPaginated<IProductCategory[]>>> {
    const searchParams = qs.stringify(
      { skip: 0, take: 100, where: { warehouse: false }, orderBy: { created_at: 'asc' } },
      { encodeValuesOnly: true }
    );

    const { data } = await newBackendHttpV2.get<IUniversalResponse<IPaginated<IProductCategory[]>>>(
      `/categories?${searchParams}`
    );

    return data;
  }

  static async checkAvailableProductsById(
    arr: number[]
  ): Promise<IUniversalResponse<{ products: IProductNew[] }>> {
    const { data } = await newBackendHttp.post<IUniversalResponse<{ products: IProductNew[] }>>(
      '/products/available',
      {
        id: arr,
      }
    );

    return data;
  }

  static async getAllRubrics(): Promise<IUniversalResponse<IPaginated<IProductRubric[]>>> {
    const query = qs.stringify({
      skip: 0,
      take: 200,
    });

    const { data } = await newBackendHttpV2.get<IUniversalResponse<IPaginated<IProductRubric[]>>>(
      `/rubrics?${query}`
    );

    return data;
  }

  static async getRubricBundleById(
    id: string | number
  ): Promise<IUniversalResponse<IRubricBundle>> {
    const { data } = await newBackendHttpV2.get<IUniversalResponse<IRubricBundle>>(
      `/rubrics/rubric-bundles/${id}`
    );

    return data;
  }

  static async getAllPostcards(): Promise<IUniversalResponse<IPostcard[]>> {
    const { data } = await newBackendHttpV2.get<IUniversalResponse<IPostcard[]>>(
      '/products/postcards'
    );

    return data;
  }
}

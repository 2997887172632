import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import createWebStorage from 'redux-persist/lib/storage/createWebStorage';
import thunk from 'redux-thunk';

import {
  availableAttributesApi,
  catalogueApi,
  categoriesApi,
  citiesApi,
  headerBannersApi,
  headerMenuApi,
  ordersApi,
  productsApi,
  productsCheckerApi,
  promotionsApi,
  reviewsApi,
  rubricsApi,
} from '@/store/api';
import {
  basketForm,
  checkoutProducts,
  city,
  deliveryLimitsModal,
  drawer,
  favoriteProducts,
  flowerInstructionModal,
  individualOrderModal,
  loginUser,
  mainInfoModal,
  mobileAppAdvertModal,
  mobileApplicationQrModal,
  oneClickOrder,
  partsPaymentModal,
  paymentInformationModal,
  sortingProducts,
  supportModal,
} from '@/store/slices';

import { LS_CHECKOUT_PRODUCTS, LS_FAVORITES, LS_USER } from '@/constants/local-storage.constant';

const createNoopStorage = () => {
  return {
    getItem(_key: any) {
      return Promise.resolve(null);
    },
    setItem(_key: any, value: any) {
      return Promise.resolve(value);
    },
    removeItem(_key: any) {
      return Promise.resolve();
    },
  };
};

const storage = typeof window !== 'undefined' ? createWebStorage('local') : createNoopStorage();

const favoritePersistConfig = {
  key: LS_FAVORITES,
  storage,
};

const userPersistConfig = {
  key: LS_USER,
  storage,
  blacklist: ['loginPage', 'haveError', 'allowClickBtn', 'linkAfterLogin', 'currentBonusCount'],
};

const checkoutProductsPersistConfig = {
  key: LS_CHECKOUT_PRODUCTS,
  storage,
  whitelist: ['products'],
};

const persistedFavoriteReducer = persistReducer(favoritePersistConfig, favoriteProducts.reducer);

const persistedUserReducer = persistReducer(userPersistConfig, loginUser.reducer);

const persistedCheckoutProductsReducer = persistReducer(
  checkoutProductsPersistConfig,
  checkoutProducts.reducer
);

export const store = configureStore({
  reducer: {
    individualOrderModal: individualOrderModal.reducer,
    user: persistedUserReducer,
    checkoutProducts: persistedCheckoutProductsReducer,
    basketForm: basketForm.reducer,
    favoriteProducts: persistedFavoriteReducer,
    sortingProducts: sortingProducts.reducer,
    oneClickOrder: oneClickOrder.reducer,
    mainInfoModal: mainInfoModal.reducer,
    mobileAppAdvertModal: mobileAppAdvertModal.reducer,
    city: city.reducer,
    supportModal: supportModal.reducer,
    drawer: drawer.reducer,
    mobileApplicationQrModal: mobileApplicationQrModal.reducer,
    partsPaymentModal: partsPaymentModal.reducer,
    flowerInstructionModal: flowerInstructionModal.reducer,
    paymentInformationModal: paymentInformationModal.reducer,
    deliveryLimitsModal: deliveryLimitsModal.reducer,
    [availableAttributesApi.reducerPath]: availableAttributesApi.reducer,
    [rubricsApi.reducerPath]: rubricsApi.reducer,
    [categoriesApi.reducerPath]: categoriesApi.reducer,
    [citiesApi.reducerPath]: citiesApi.reducer,
    [productsCheckerApi.reducerPath]: productsCheckerApi.reducer,
    [catalogueApi.reducerPath]: catalogueApi.reducer,
    [productsApi.reducerPath]: productsApi.reducer,
    [headerBannersApi.reducerPath]: headerBannersApi.reducer,
    [headerMenuApi.reducerPath]: headerMenuApi.reducer,
    [reviewsApi.reducerPath]: reviewsApi.reducer,
    [promotionsApi.reducerPath]: promotionsApi.reducer,
    [ordersApi.reducerPath]: ordersApi.reducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(
      thunk,
      availableAttributesApi.middleware,
      rubricsApi.middleware,
      categoriesApi.middleware,
      productsCheckerApi.middleware,
      citiesApi.middleware,
      catalogueApi.middleware,
      productsApi.middleware,
      headerBannersApi.middleware,
      headerMenuApi.middleware,
      reviewsApi.middleware,
      promotionsApi.middleware,
      ordersApi.middleware
    ),
});
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { IFiltersProps, ProductService } from 'src/services/product.service';

import { IProductAttribute } from '@/types/new/products';

export const availableAttributesApi = createApi({
  reducerPath: 'availableAttributesApi',
  baseQuery: fetchBaseQuery(),
  endpoints: (build) => {
    return {
      getAvailableAttributes: build.query<IProductAttribute[], IFiltersProps | undefined>({
        queryFn: async (categories) => {
          const { data: attributes } = await ProductService.getAvalableAttributes(categories);
          return { data: attributes };
        },
      }),
    };
  },
});

export const { useGetAvailableAttributesQuery, useLazyGetAvailableAttributesQuery } =
  availableAttributesApi;

export const useGetAvailableAttributesState =
  availableAttributesApi.endpoints?.getAvailableAttributes.useQueryState;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { createSelector } from 'reselect';

import { promotionService } from '@/services/promotions.service';

import { IPromotion } from '@/types/new/promotions';

export const promotionsApi = createApi({
  reducerPath: 'promotionsApi',
  baseQuery: fetchBaseQuery(),
  tagTypes: ['PromotionsTag'],
  endpoints: (build) => ({
    getLastActivePromotion: build.query<IPromotion, void>({
      queryFn: async () => {
        const data = await promotionService.getLastActive();
        return {
          data: data.data.items[0] ?? null,
          meta: {},
        };
      },
    }),
  }),
});

export const { useGetLastActivePromotionQuery } = promotionsApi;

const selectLastActivePromotionResult = promotionsApi.endpoints.getLastActivePromotion.select();

export const selectLastActivePromotion = createSelector(
  selectLastActivePromotionResult,
  (promotionsResult) => promotionsResult?.data ?? null
);

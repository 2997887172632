import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { ProductService } from '@/services/product.service';

export const productsApi = createApi({
  reducerPath: 'totalProductsApi',
  baseQuery: fetchBaseQuery(),
  endpoints: (build) => ({
    getTotalProducts: build.query<number, void>({
      queryFn: async () => {
        const { data } = await ProductService.getByFilters({ limit: 0 });
        return { data: data?.meta?.pagination?.total };
      },
    }),
  }),
});

export const { useGetTotalProductsQuery } = productsApi;

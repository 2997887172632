import { DefaultSeoProps } from 'next-seo';

import { markdownToPlainText } from '@/helpers/string/markdownToPlainText';

import { IProductNew } from '@/types/new/products';

export const SEO_CONSTANTS = {
  sitename: 'AzaliaNow',
  website: 'https://azalianow.ru',
  locale: 'ru_RU',
  title: 'Доставка цветов в Москве – онлайн-магазин AzaliaNow',
  description:
    'Доставка цветов в Москве - AzaliaNow. Интернет-магазин цветов, букетов, комнатных растений и декора.',
};

const locationTags = [
  { name: 'language', content: 'ru-RU' },
  { name: 'geo.region', content: 'RU-MOW' },
  { name: 'geo.placename', content: 'Москва' },
  { name: 'geo.position', content: '55.755826;37.6173' },
  { name: 'ICBM', content: '55.755826, 37.6173' },
];

const alTags = [
  { property: 'al:telegram:url', content: 'https://t.me/azalianow' },
  { property: 'al:telegram:app_store_id', content: '686449807' },
  { property: 'al:telegram:app_name', content: 'Telegram' },
  { property: 'al:ios:app_name', content: 'Instagram' },
  { property: 'al:ios:url', content: 'instagram://user?username=azalianow.ru' },
  { property: 'al:android:url', content: 'https://www.instagram.com/azalianow.ru' },
  { property: 'al:android:package', content: 'com.instagram.android' },
  { property: 'al:android:app_name', content: 'Instagram' },
  { property: 'al:ios:app_name', content: 'WhatsApp' },
  {
    property: 'al:ios:url',
    content: 'whatsapp://send?phone=+74958220709&text=Здравствуйте,%20хочу%20заказать%20букет',
  },
  { property: 'al:android:app_name', content: 'WhatsApp' },
  {
    property: 'al:android:url',
    content:
      'https://api.whatsapp.com/send?phone=+74958220709&text=Здравствуйте,%20хочу%20заказать%20букет',
  },
  { property: 'al:ios:app_name', content: 'Facebook' },
  { property: 'al:ios:url', content: 'fb://profile/100084293112997' },
  { property: 'al:android:app_name', content: 'Facebook' },
  {
    property: 'al:android:url',
    content: 'https://www.facebook.com/profile.php?id=100084293112997',
  },
  { property: 'al:android:package', content: 'ru.azalianow.azalianow' },
];

const getTwitterDefaultTags = (url: string) => [
  { name: 'twitter:card', content: 'summary_large_image' },
  { name: 'twitter:site', content: '@azalianow' },
  { name: 'twitter:url', content: url },
  { name: 'twitter:title', content: SEO_CONSTANTS.title },
  {
    name: 'twitter:description',
    content: SEO_CONSTANTS.description,
  },
  { name: 'twitter:image', content: 'https://azalianow.ru/static/logo/og-logo-image.png' },
  { name: 'twitter:image:alt', content: 'AzaliaNow' },
];

export const SEO_DEFAULT: (url: string) => DefaultSeoProps = (url) => ({
  defaultTitle: SEO_CONSTANTS.title,
  description: SEO_CONSTANTS.description,
  openGraph: {
    type: 'website',
    title: SEO_CONSTANTS.title,
    description: SEO_CONSTANTS.description,
    locale: SEO_CONSTANTS.locale,
    url,
    siteName: SEO_CONSTANTS.sitename,
    images: [{ url: 'https://azalianow.ru/static/logo/og-logo-image.png' }],
    defaultImageHeight: 630,
    defaultImageWidth: 1200,
  },
  themeColor: '#ffffff',
  additionalMetaTags: [...locationTags, ...alTags, ...getTwitterDefaultTags(url)],
});

export const OrganizationSchema = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'AzaliaNow',
  description:
    '100% свежие цветы на любой бюджет. Бесплатная, экспресс и точно ко времени доставка. Открытка в подарок. Круглосуточно.',
  keywords:
    'Купить цветы, купить цветы в Москве, купить цветы недорого, купить цветы с доставкой, купить цветы в Московской области, купить розы, купить пионы, купить тюльпаны, букеты в коробках, букеты в корзинках, авторские букеты, купить букет цветов, цветы купить интернет магазин, где можно купить цветы, купить живые цветы, купить большой цветок, купить цветы дешево, красные цветы купить, 100 цветов купить, купить цветы розы, цветочный, цветочный магазин, цветочная лавка',
  url: 'https://azalianow.ru',
  logo: 'https://azalianow.ru/static/logo/og-logo-image.png',
  image: 'https://azalianow.ru/static/logo/og-logo-image.png',
  telephone: '+7 495 822 07 09',
  sameAs: [
    'https://vk.com/azalianow',
    'https://www.instagram.com/azalianow.ru',
    'https://t.me/azalianow',
  ],
};

export const LocalBusinessSchema = {
  '@context': 'https://schema.org',
  '@type': 'LocalBusiness',
  name: 'AzaliaNow Москва',
  image: 'https://azalianow.ru/static/logo/og-logo-image.png',
  telephone: '+7 495 822 07 09',
  address: {
    '@type': 'PostalAddress',
    streetAddress: '1-й Магистральный проезд 12с1',
    addressLocality: 'Москва',
    postalCode: '123290',
    addressCountry: 'RU',
  },
  openingHoursSpecification: {
    '@type': 'OpeningHoursSpecification',
    dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    opens: '09:00',
    closes: '21:00',
  },
};

export const getProductSchema = ({
  id,
  name,
  slug,
  price,
  description,
  media,
  is_in_stock,
  number_of_purchases,
}: IProductNew) => {
  const date = new Date();
  const priceValidUntil = new Date(date.setDate(date.getDate() + 2)).toISOString().split('T')[0];
  const imageUrl = media[0].url;
  const url = `https://azalianow.ru/product/${slug}`;
  const sku = id;
  const brand = 'AzaliaNow';
  const aggregateRating = {
    '@type': 'AggregateRating',
    ratingValue: '5',
    ratingCount: number_of_purchases,
  };

  return {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name,
    description: markdownToPlainText(description ?? ''),
    image: imageUrl,
    sku,
    brand: {
      '@type': 'Brand',
      name: brand,
    },
    offers: {
      '@type': 'Offer',
      url,
      price,
      priceCurrency: 'RUB',
      itemCondition: 'https://schema.org/NewCondition',
      availability: is_in_stock ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock',
      priceValidUntil,
      hasMerchantReturnPolicy: {
        '@type': 'MerchantReturnPolicy',
        name: 'No Returns Policy',
        returnPolicyCategory: 'https://schema.org/NoReturnRefund',
        merchantReturnDays: 0,
        url: 'https://azalianow.ru/returns-policy',
        additionalType: 'https://schema.org/MerchantReturnPolicy',
      },
      shippingDetails: [
        {
          '@type': 'OfferShippingDetails',
          transitTimeLabel: 'Same Day (3-hour window)',
          shippingDestination: {
            '@type': 'DefinedRegion',
            addressCountry: 'RU',
          },
          deliveryTime: {
            '@type': 'ShippingDeliveryTime',
            transitTime: {
              '@type': 'QuantitativeValue',
              minValue: 3,
              maxValue: 3,
              unitCode: 'HUR',
            },
          },
          name: 'Free Shipping',
        },
        {
          '@type': 'OfferShippingDetails',
          transitTimeLabel: 'Same Day (2-hour window)',
          shippingDestination: {
            '@type': 'DefinedRegion',
            addressCountry: 'RU',
          },
          deliveryTime: {
            '@type': 'ShippingDeliveryTime',
            transitTime: {
              '@type': 'QuantitativeValue',
              minValue: 2,
              maxValue: 2,
              unitCode: 'HUR',
            },
          },
          name: 'Express Shipping',
        },
        {
          '@type': 'OfferShippingDetails',
          transitTimeLabel: 'Same Day (1-hour window)',
          shippingDestination: {
            '@type': 'DefinedRegion',
            addressCountry: 'RU',
          },
          deliveryTime: {
            '@type': 'ShippingDeliveryTime',
            transitTime: {
              '@type': 'QuantitativeValue',
              minValue: 1,
              maxValue: 1,
              unitCode: 'HUR',
            },
          },
          name: 'Timed Delivery',
        },
      ],
    },
    aggregateRating,
  };
};

export const getBreadcrumbsSchema = (
  items: { name: string; href: string; position: number }[]
) => ({
  '@context': 'https://schema.org',
  '@type': 'BreadcrumbList',
  itemListElement: items.map((item) => ({
    '@type': 'ListItem',
    position: item.position,
    name: item.name,
    item: item.href,
  })),
});

import CardIcon from './card.svg';
import DolyameIcon from './dolyame.svg';
import HoumIcon from './houm.svg';
import OtpIcon from './otp.svg';
import PodelyIcon from './podely.svg';
import RobokassaIcon from './robokassa.svg';
import SberbankIcon from './sberbank.svg';
import SbpIcon from './sbp.svg';
import T_PayIcon from './t-pay.svg';
import YandexIcon from './yandex.svg';
import YoomoneyIcon from './yoomoney.svg';

export {
  CardIcon,
  DolyameIcon,
  HoumIcon,
  OtpIcon,
  PodelyIcon,
  RobokassaIcon,
  SberbankIcon,
  SbpIcon,
  T_PayIcon,
  YandexIcon,
  YoomoneyIcon,
};

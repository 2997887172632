import clsx from 'clsx';
import { HTMLAttributes, createElement, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Image } from '@/components/image';
import Button from '@/components/ui/buttons/buttons/Button';
import { Field } from '@/components/ui/form-elements/field/Field';
import TextareaField from '@/components/ui/form-elements/textarea-field/TextareaField';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useTypedSelector } from '@/hooks/useTypedSelector';

import { OrderService } from '@/services/order.service';
import { UploadService } from '@/services/upload.service';

import { changeLoginPage } from '@/store/slices/login-user/login-user.slice';

import styles from './IndividualOrder.module.scss';
import { useFilePreview } from './useFilePreview';

const penToSquare = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.8 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
  </svg>
);

const plusIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
  </svg>
);

type FormData = {
  wishes: string;
  phone: string;
  image: File[];
};

type Props = HTMLAttributes<HTMLDivElement> & {
  titleElement?: 'div' | 'h2';
  title?: string;
  description?: string;
};

const initialValues = {
  wishes: '',
  phone: '',
  image: [],
};

const IMAGE_FORMATS = ['png', 'jpeg', 'jpg', 'bmp', 'webp', 'gif'];

function validateImage(value: File[]) {
  if (value.length === 0) {
    return 'Прикрепите файл для валидации';
  }

  const fileSize = value[0].size / 1024 / 1024;

  const fileExtension = value[0].name.split('.').pop()?.toLowerCase();
  if (fileExtension && !IMAGE_FORMATS.includes(fileExtension)) {
    return 'Неправильный формат фотографии';
  }

  if (fileSize > 10) {
    return 'Слишком большой размер фотографии';
  }

  return true;
}

const IndividualOrder = ({ className, titleElement = 'div', title, description }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isAuth = useTypedSelector((state) => state.user.login);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    resetField,
    reset,
    setError,
  } = useForm<FormData>({ defaultValues: initialValues, mode: 'onChange' });

  const TitleNode = createElement(
    titleElement,
    {
      className: styles.title,
    },
    title || 'Соберем букет по фото'
  );

  const dispatch = useAppDispatch();

  const openLoginModal = () => dispatch(changeLoginPage(1));

  const imageFile = watch('image');
  const imagePreview = useFilePreview(imageFile);

  const submitForm = async (formValues: FormData) => {
    if (!isAuth) {
      openLoginModal();
      return;
    }

    let formData = new FormData();
    formData.append('files', formValues.image[0]);

    try {
      setIsLoading(true);
      const { data } = await UploadService.uploadFileWithoutRef(formData);

      await OrderService.sendIndividualOrder({
        wishes: formValues.wishes,
        phone: formValues.phone,
        image: data.media.data[0].url,
      });
      reset();
    } catch (e: any) {
      setError('image', { message: 'Произошла ошибка, попробуйте еще раз !' });
    } finally {
      setIsLoading(false);
    }
  };

  const onRemoveImage = () => {
    resetField('image');
  };

  return (
    <div className={clsx(styles.wrapper, className)}>
      <div className={styles.image}>
        <Image
          src="/static/images/individualorder.webp"
          fill
          loading="lazy"
          alt="Индивидуальный заказ"
          sizes="535px"
        />
      </div>

      <div className={styles.body}>
        <span className={styles.subTitle}>Пришлите картинку или фото</span>

        {TitleNode}

        <p className={styles.description}>
          {description ||
            'Просто пришлите нам букет, который вы нашли в интернете или в социальных сетях.'}
        </p>

        <form className={styles.form} onSubmit={handleSubmit(submitForm)}>
          <div className={styles.inputs}>
            {imagePreview && !errors.image ? (
              <div className={styles.uploadedImage}>
                <div className={styles.editButton} onClick={onRemoveImage}>
                  {penToSquare}
                </div>
                <Image src={imagePreview} fill alt="Ваш букет" loading="lazy" />
              </div>
            ) : (
              <label className={styles.addPhotoButton}>
                <input
                  {...register('image', {
                    required: 'Прикрепите фотографию',
                    validate: validateImage,
                  })}
                  type="file"
                  accept={IMAGE_FORMATS.map((i) => `image/${i}`).join(', ')}
                  id="picture"
                  style={{ display: 'none' }}
                />
                <div className={styles.content}>
                  {plusIcon}
                  <p>Добавить фото</p>
                </div>
              </label>
            )}

            {errors.image && <div className={styles.errorMessage}>{errors.image.message}</div>}

            <Controller
              control={control}
              name="wishes"
              render={({ field: { value, onChange } }) => (
                <TextareaField
                  className={styles.input}
                  labelText="Комментарий к букету (необязательно)"
                  value={value}
                  name="wishes"
                  withAutoSize={false}
                  variant="white"
                  maxLength={1000}
                  onChange={onChange}
                />
              )}
            />

            {errors.wishes && <div className={styles.errorMessage}>{errors.wishes.message}</div>}

            <Controller
              control={control}
              name="phone"
              rules={{
                required: { value: true, message: 'Введите номер телефона' },
                validate: (value) => {
                  const clearedPhoneNumber = value.replace(/\D/g, '');

                  if (clearedPhoneNumber.length !== 11) {
                    return 'Номер телефона должен содержать 11 цифр';
                  } else {
                    return true;
                  }
                },
              }}
              render={({ field: { value, onChange } }) => (
                <Field
                  className={styles.input}
                  type="phone"
                  labelText="Номер телефона"
                  value={value}
                  name="phone"
                  onChange={onChange}
                />
              )}
            />

            {errors.phone && <div className={styles.errorMessage}>{errors.phone.message}</div>}
          </div>

          <Button type="submit" fullWidth isLoading={isLoading}>
            Отправить
          </Button>
        </form>
      </div>
    </div>
  );
};

export default IndividualOrder;
